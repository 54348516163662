import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { browserTracingIntegration } from '@sentry/angular';
import * as Sentry from '@sentry/angular';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import versionJson from './environments/version.json';

Sentry.init({
    dsn: environment.sentry.dsn,
    release: `icp-frontend-dashboard@${versionJson.version}`,
    integrations: [browserTracingIntegration()],
    environment: environment.env,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentry.tracesSampleRate,
    enabled: environment.production,
});

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
