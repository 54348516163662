import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdpService } from '@icp/angular/idp';
import { Oauth2SignOutQueryDto, RolesService, UserService } from '@icp/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { catchError, filter, from, map, of, switchMap, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ErrorService } from '../../core/services';
import { SharedActions } from '../../state/shared/shared.actions';
import { LANGUAGE_STORAGE_KEY, sharedFeature } from '../../state/shared/shared.feature';
import * as AuthActions from './auth.actions';
import { selectActiveUser } from './auth.selectors';

@Injectable()
export class AuthEffects {
    private errorService = inject(ErrorService);
    private store = inject(Store);
    private actions$ = inject(Actions);
    private router = inject(Router);
    private idpService = inject(IdpService);
    private userService = inject(UserService);
    private rolesService = inject(RolesService);

    autoLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.startAutoLogin),
            switchMap(() =>
                this.idpService.getCurrentSession().pipe(
                    switchMap((authSession) => {
                        if (authSession) {
                            return this.userService.getCurrentUser().pipe(
                                map((user) => AuthActions.loginSuccess({ user, redirect: false })),
                                // for auto login don't show error messages => let them do the login and then give an error message.
                                catchError((error: HttpErrorResponse) =>
                                    of(AuthActions.loginFailed({ error: error.message })),
                                ),
                            );
                        }
                        return of(AuthActions.logoutSuccess({ redirect: false }));
                    }),
                ),
            ),
        ),
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            switchMap((action) =>
                from(this.idpService.logout()).pipe(
                    map(() => AuthActions.logoutSuccess({ redirect: true })),
                    tap(() => {
                        const url = new URL(`${environment.idpURL}/idp/oauth2/v1/sign-out`);
                        const params: Oauth2SignOutQueryDto = {
                            client_id: environment.idpClientId,
                            post_logout_redirect_uri: window.location.origin,
                        };
                        for (const [key, value] of Object.entries(params)) {
                            url.searchParams.append(key, value);
                        }
                        window.location.href = url.toString();
                    }),
                    catchError((error) => this.errorService.handleError(action, AuthActions.loginFailed, error)),
                ),
            ),
        ),
    );

    onLoginLoadUserRoles = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginSuccess),
            tap((action) => Sentry.setUser({ id: action.user.id })),
            map((action) => AuthActions.getUserRoles({ redirect: action.redirect ?? true })),
        ),
    );

    goToStartOnLoginOrLogout = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.logoutSuccess, AuthActions.getUserRolesSuccess),
                filter((action) => action.redirect),
                map(() => this.router.navigate(['/'])),
            ),
        { dispatch: false },
    );

    fetchUserRoles = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getUserRoles),
            concatLatestFrom(() => this.store.select(selectActiveUser)),
            switchMap(([action, user]) =>
                user
                    ? this.rolesService.getRolesForUser().pipe(
                          map((roles) => AuthActions.getUserRolesSuccess({ roles, redirect: action.redirect })),
                          catchError((error) =>
                              this.errorService.handleError(action, AuthActions.getUserRolesFailed, error),
                          ),
                      )
                    : of(AuthActions.getUserRolesSuccess({ roles: [], redirect: action.redirect })),
            ),
        ),
    );

    updateUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.updateUserProfile),
            switchMap((action) =>
                this.userService.updateCurrentUser(action.payload).pipe(
                    map((user) => AuthActions.updateUserProfileSuccess({ user })),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.updateUserProfileFailed, error),
                    ),
                ),
            ),
        ),
    );

    afterUpdateUserProfileSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.updateUserProfileSuccess, AuthActions.loginSuccess),
            map((action) => SharedActions.setCurrentLanguage({ language: action.user.language })),
        ),
    );

    storeLanguageInStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SharedActions.setCurrentLanguage),
                concatLatestFrom(() => this.store.select(sharedFeature.selectCurrentLang)),
                filter(([action, currentLang]) => action.language === currentLang),
                tap(([action]) => localStorage.setItem(LANGUAGE_STORAGE_KEY, action.language)),
            ),
        { dispatch: false },
    );
}
